/** Class refering to the Banner. */
var Banner = class Banner {

    /**
     * Get the banner object from the DOM.
     * @return {jQuery Object} The banner.
     */
    get banner() {
        return $('#banner');
    }

    /**
     * Get the banner's class attribute from the DOM.
     * @return {string} The banner's class attribute.
     */
    get type() {
        return this.banner.attr('class');
    }

    /**
     * Get the banner's H3 element from the DOM.
     * @return {jQuery Object} The banner's <h3>.
     */
    get title() {
        return this.banner.find('h3');
    }

    /**
     * Get the banner's P element from the DOM.
     * @return {jQuery Object} The banner's <p>.
     */
    get detail() {
        return this.banner.find('p');
    }

    /**
     * Get the banner's DIV.choices element from the DOM.
     * @return {jQuery Object} The banner's <div class=""choices>.
     */
    get choices() {
        return this.banner.find('.choices');
    }

    /**
     * Set the banner's class attribute in the DOM.
     * @param {string} type - The type value.
     * @return {string} The Banner.
     */
    set type(type) {
        this.banner.attr('class', type);
        return this;
    }

    /**
     * Set the banner's H3 element in the DOM.
     * @param {string} title - The title HTML value.
     * @return {string} The Banner.
     */
    set title(title) {
        this.title.append(title);
        return this;
    }

    /**
     * Set the banner's P element in the DOM.
     * @param {string} detail - The detail HTML value.
     * @return {string} The Banner.
     */
    set detail(detail) {
        this.detail.append(detail);
        return this;
    }

    /**
     * Set the banner's DIV.choices element in the DOM.
     * @param {string} detail - The DIV HTML value.
     * @return {string} The Banner.
     */
    set choices(choices) {
        this.choices.append(choices);
        return this;
    }

    /**
     * Hide every elements of the banner.
     */
    emptyBanner(SELF) {
        $(SELF.title).children().stop().fadeOut();
        $(SELF.detail).children().stop().fadeOut();
        $(SELF.choices).children().stop().fadeOut();
    }

    /**
     * Hide the banner on a given direction.
     * @param {string} side - The direction to go hide.
     */
    hideBanner(side) {
        const SELF = this;
        var direction = {}
        direction[side] = -1000;
        $(this.banner).stop().animate(direction, 300).promise().done(function () {
            SELF.emptyBanner(SELF)
        });
    }

    /**
     * Show the banner for a given time.
     * @param {string} type - The type of alert you want.
     * @param {string} span - The class of the title and detail you want shown.
     * @param {boolean} hasChoices - Enable the choices display.
     * @param {string} side - The direction to show from.
     * @param {string} time - The time in milliseconds it stays there.
     */
    showBanner(type, span, hasChoices = false, side = 'right', time = null) {
        const SELF = this;
        var direction = {};
        direction[side] = -10;

        this.type = type;
        $(this.banner).css(side, -1000);
        $(this.banner).stop().animate(direction, 300);

        if ($(this.title).find(span).length) {
            $(this.title).find(span).fadeIn().css('display', 'inline-block');
        }

        if ($(this.detail).find(span).length) {
            $(this.detail).find(span).fadeIn().css('display', 'inline-block');
        }

        if (hasChoices) {
            if ($(this.choices).find(span).length) {
                $(this.choices).find(span).fadeIn().css('display', 'inline-block');
            }
        }

        if (time) {
            setTimeout(function () {
                SELF.hideBanner(side);
            }, time)
        }
    }

};



$(function () {
    var ban = new Banner();
    $(document).on('click', '#banner', function () {
        ban.hideBanner('right');
    })
})
